import { useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";
// import PresentationNew from "layouts/pages/presentation_new";
// import Coworking from "pages/LandingPages/Coworking";
import HomePage from "pages/LandingPages/HomePage";
import Privacy from "pages/Privacy";
import Error404 from "pages/Errors/NotFound";
import ContactPage from "pages/Contact";

// Material Kit 2 PRO React routes
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        {/* <Route path="/home" element={<PresentationNew />} /> */}
        {/* <Route path="/" element={<Coworking />} /> */}
        {/* <Route path="/test" element={<DownloadSection />} /> */}
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/" element={<HomePage />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </ThemeProvider>
  );
}
