import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

function Blocks() {
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <FilledInfoCard
            variant="gradient"
            color="info"
            icon="flag"
            title="Getting Started"
            description="Check the possible ways of working with our product and the necessary files for building your own project."
            action={{
              type: "external",
              route: "https://kerga.fr",
              label: "Let's start",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FilledInfoCard
            color="info"
            icon="precision_manufacturing"
            title="Plugins"
            description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
            action={{
              type: "external",
              route: "https://kerga.fr",
              label: "Read more",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FilledInfoCard
            color="info"
            icon="apps"
            title="Components"
            description="Material Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
            action={{
              type: "external",
              route: "https://kerga.fr",
              label: "Read more",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Blocks;
