import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function Error404() {
  return (
    <MKBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{ textAlign: "center" }}
    >
      <MKTypography variant="h1" color="dark" gutterBottom>
        404
      </MKTypography>

      <MKTypography variant="h4" color="dark" gutterBottom>
        Sorry, the page you&apos;re looking for doesn&apos;t exist.
      </MKTypography>

      <MKButton variant="gradient" color="dark" sx={{ mt: 3 }} href={"https://kerga.fr"}>
        Back to kerga.fr
      </MKButton>
    </MKBox>
  );
}

export default Error404;
