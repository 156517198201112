/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import icon_ from "assets/logos/black_fill_name.svg";

function Privacy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/contact",
          label: "contact",
          color: "dark",
        }}
        transparent
        center
        brand={<img src={icon_} alt="Logo Kerga" style={{ height: "40px" }} />}
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Privacy & Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: July 01 2024
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://kerga.fr"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      Kerga
                    </MKTypography>{" "}
                    is committed to protecting your privacy. This Privacy Policy explains how we
                    collect, use, disclose, and safeguard your information when you visit our
                    website{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://kerga.fr"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      https://kerga.fr
                    </MKTypography>
                    , engage with our services, or otherwise interact with us. Please read this
                    policy carefully to understand our practices regarding your your personal data
                    your personal data and how we will treat it.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Information We Collect
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We may collect personal information from you when you voluntarily submit it to
                    us. This can include:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Contact information (such as name, email address, phone number)
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Business information (such as company name, job title)
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Payment information (credit card details, billing address)
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Other information relevant to client projects and communications
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How We Use Your Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We use the information we collect in the following ways:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To provide and maintain our services to you
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To communicate with you, including responding to your inquiries and requests
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To process payments and invoicing
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To improve our website and services
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To personalize your experience
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To comply with legal obligations
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Sharing Your Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We may share your information with third parties only in the following
                    circumstances:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        With service providers who assist us in operating our website or conducting
                        our business
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        With your consent or at your direction
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To comply with legal requirements, such as in response to a subpoena or
                        similar legal process
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        To protect our rights, protect your safety or the safety of others,
                        investigate fraud, or respond to a government request
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Security of Your Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We are committed to ensuring that your information is secure. We have
                    implemented appropriate technical and organizational measures to safeguard and
                    secure the information we collect.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Your Rights and Choices
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    You have the right to:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Access and update your personal information
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Request the deletion of your personal information
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Opt-out of receiving marketing communications
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Contact Us
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    If you have any questions or concerns about this Privacy Policy, please contact
                    us at contact@kerga.fr. By using our website or services, you agree to the
                    collection and use of information as outlined in this Privacy Policy.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
