// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import bgImage from "assets/images/bg_laptop.jpg";
import team1 from "assets/images/phd.png";
import team2 from "assets/images/pfe.png";
import team3 from "assets/images/cv.png";

function AllDownl() {
  return (
    <MKBox
      component="section"
      position="relative"
      py={12}
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      borderRadius="15px"
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="3rem"
              height="3rem"
              variant="gradient"
              bgColor="info"
              color="white"
              shadow="md"
              borderRadius="lg"
              mb={2}
            >
              <Icon>download</Icon>
            </MKBox>
            <MKTypography variant="h3" color="white">
              Download Section
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Explore My Work
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Resume"
                position={{ color: "info", label: "07/2024" }}
                description="This section highlights Gaëtan's professional and academic journey, showcasing his experiences, skills, and accomplishments"
                link="/static/Resume_GaëtanDesrues.pdf"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Ph.D. Manuscript"
                position={{ color: "info", label: "2023" }}
                description="Personalized 3D Electromechanical Models of the Heart for Cardiac Resynchronisation Therapy Planning in Heart Failure Patients"
                link="/static/PhD_GaëtanDesrues.pdf"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Reseach Internship Report"
                position={{ color: "info", label: "2019" }}
                description="Model Order Reduction for 3D Electromechanical Heart Simulation"
                link="/static/PFE_GaëtanDesrues.pdf"
              />
            </MKBox>
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Marquez Garcia"
                position={{ color: "info", label: "JS Developer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AllDownl;
